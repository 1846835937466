a {
    text-decoration: unset;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #5D6373;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

.autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    /* color: #ff0052; */
}

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #ffb300;
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}

.swiper-button-prev,
.swiper-button-next {
    color: #fff;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    top: 31px;
    background-color: #53555C;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 11px;
}

.divide::after {
    content: "";
    display: block;
    position: absolute;
    top: 20%;
    right: 0px;
    height: 60%;
    width: 1px;
    background: linear-gradient(360deg, rgba(62, 63, 65, .248811) 1.44%, #394142 54.44%, rgba(55, 55, 55, .248981) 100%);
}

.swiper-slide.active:after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to right, #d4a017 0%, #d49100 100%);
}


.swiper-slide.active:before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    background: linear-gradient(to right, #d4a017 0%, #d49100 100%);
    border-radius: 100%;
    filter: blur(18px);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

/* ---------------animation--------------- */
@keyframes imgscale {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: .75;
    }

    50% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: .75;
    }
}